<template>
    <div v-if="category && product && variant" class="view product" @click="cancelExpand" :limited="product.limited">
		<div class="view-title" v-if="!isMobile">
			<div class="collection">2019 - 2020<br>Black Pug Studio Collection</div>
			<h1>{{category.name}}</h1>
			<!-- <h3>{{category.name}}</h3> -->
		</div>
		<div class="container">
			<div class="product-image" ref="imageContainer" :dynamic="`product-image-${product && product.id}`">
				<!-- <div class="limited-tag" v-if="product.limited"></div> -->
				<swiper ref="swiper" :options="swiperOptions" class="product-image-slider" v-if="isMobile">
					<swiper-slide class="image-slide" v-for="file in variant.images" :key="file.name">
						<BpsImage :placeholder="file.placeholder" :src="file.url" />
					</swiper-slide>
				</swiper>
				<div class="bullets" v-if="isMobile">
					<button :selected="slideIndex === i" class="bullet" v-for="(file, i) in variant.images" :key="'bullet-' + i" @click="$refs.swiper.swiper.slideTo(i)"></button>
				</div>
				<BpsImage ref="productImage" @click.stop.native="expandImage" v-if="!isMobile && selectedImage" :placeholder="selectedImage.placeholder" :src="selectedImage.url" />
				<div class="product-images" v-if="!isMobile">
					<a v-for="file in variant.images" :key="file.name" @click="selectedImage = file">
						<BpsImage :placeholder="file.placeholder" :src="file.url" />
					</a>
				</div>
			</div>
			<div class="product-details">
				<h1 class="product-name">{{product.name}}</h1>
				<div class="product-price">€{{variant.retail_price}}</div>
				<div class="product-limited" v-if="product.limited">Limited Edition</div>
				<div class="product-brand" v-if="variant.details.brand">Brand: {{variant.details.brand}}</div>
				<div class="product-limited" v-if="!product.limited">&nbsp;</div>
				<div class="product-unavailable" v-if="product.unavailable">
					This product is currently unavailable.
				</div>
				<div class="product-variants" v-if="!product.unavailable && hasColors">
					<label>Choose your favorite color</label>
					<div class="product-colors">
						<button :aria-label="key" :selected="selectedColor === key" class="product-color" :title="key" v-for="(v, key) in productColors" :key="`color-${v.id}-${key}`" @click="selectVariant(key, null)">
							<div class="color" v-if="v.details.color_code" :style="{background: v.details.color_code}"></div>
							<div class="color" v-if="v.details.color_code2" :style="{background: v.details.color_code2}"></div>
						</button>
					</div>
				</div>
				<div class="product-variants" v-if="!product.unavailable && hasSizes">
					<label>Choose your size</label>
					<div class="product-sizes">
						<div ref="sizeCircle" class="size-circle"></div>
						<button @mouseenter="positionCircle" @mouseleave="positionCircle" :selected="selectedSize === key" class="product-size" v-for="(v, key) in productSizes" :key="`size-${v.id}-${key}`" @click="selectVariant(null, key)"><span>{{key}}</span></button>
					</div>
				</div>
				<div class="product-quantity" v-if="!product.unavailable && isMobile">
					<form @submit.prevent="quantity = 1; addToCart()">
						<button class="btn" type="submit" :disabled="!variantId || (hasColors && !selectedColor) || (hasSizes && !selectedSize)">Add to cart</button>
					</form>
				</div>
				<div class="product-description">
					<h5>Details</h5>
					<div class="product-description-text" v-html="product.description"></div>
				</div>
				<div class="product-artist">Artist Designer: {{product.artist}}</div>
				<div class="product-note">Original Gear from Black Pug Studio</div>
				<div class="product-quantity" v-if="!product.unavailable && !isMobile">
					<form @submit.prevent="addToCart">
						<input type="number" v-model="quantity" min="1" max="9999" required>
						<button class="btn" type="submit" :disabled="!variantId || (hasColors && !selectedColor) || (hasSizes && !selectedSize)">Add to cart</button>
					</form>
				</div>
				<router-link v-if="isMobile" class="btn" :to="`/categories/${product.category}`"><i class="ic ic-chevron-left"></i> Back</router-link>
			</div>
		</div>
		<div class="share" v-if="!isMobile && variant">
			<div class="copied" :active="copied"><p>Link copied!</p></div>
			<div class="share-links">
				<a ref="shareLink" class="share-link ic-link" :data-clipboard-text="pageUrl">
					<i class="ic ic-link"></i>
				</a>
				<a rel="noopener" class="share-link ic-facebook" :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`" target="_blank">
					<i class="ic ic-facebook"></i>
				</a>
				<a rel="noopener" class="share-link ic-twitter" :href="`https://twitter.com/intent/tweet?text=${variant.name}%20${pageUrl}`" target="_blank">
					<i class="ic ic-twitter"></i>
				</a>
				<a rel="noopener" class="share-link ic-pinterest" v-if="variant.images && variant.images.length" :href="`https://pinterest.com/pin/create/button/?url=${pageUrl}&media=${variant.images[0].url}&description=${variant.name}`" target="_blank">
					<i class="ic ic-pinterest"></i>
				</a>
			</div>
			<p>Share it with your friends!</p>
		</div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from "vuex"
import Clipboard from 'clipboard'
import BpsImage from '../components/BpsImage'

export default {
	name: "product",
	components: {
		BpsImage
	},
	computed: {
		...mapGetters({
			db: 'getDb',
			auth: 'getAuth',
			categories: 'getCategories',
			products: 'getProducts',
			variants: 'getVariants',
			isMobile: 'getMobile'
		}),
		product() {
			const product = this.products.find(p => p.id == this.$route.params.id || p.slug === this.$route.params.id)
			if(!product) return null
			return Object.assign(product, { variants: this.variants.filter(v => v.sync_product_id == product.id) })
		},
		category() {
			return (this.product && this.categories.find(c => c.slug === this.product.category)) || null
		},
		pageUrl() {
			return window.location.protocol + '//' + window.location.host + this.$route.path
		},
		colors() {
			return this.product && this.product.variants ? this.product.variants.reduce((colors, product) => {
				const color = Array.isArray(product.color) ? product.color.join(',') : product.color
				const index = this.colorsList.indexOf(color)
				if(index > -1) colors[index] = product
				else colors[Math.max(this.product.variants.length, colors.length)] = product
				return colors
			}, []).filter(x => x) : []
		},
		variantId() {
			return this.$route.params.variant || null
		}
	},
	data() {
		return {
			quantity: 1,
			loading: false,
			variant: null,
			colorsList: ['#211D19', '#2B2B35', '#61DF3A', '#FB478E', '#CBF862', '#982D31', '#56524F,#000000', '#55524F,#333D5A', '#57514E,#F82205', '#144074,#B6AEAB'],
			copied: false,
			selectedImage: null,
			selectedSize: null,
			selectedColor: null,
			hasColors: false,
			hasSizes: false,
			productColors: {},
			productSizes: {},
			slideIndex: 0,
			swiperOptions: {
				on: {
					slideChange: () => {
						this.slideIndex = this.$refs.swiper.swiper.activeIndex
					}
				}
			}
		}
	},
	watch: {
		product(p, previous) {
			if(!p || !previous || p.id !== previous.id) this.getVariant(true)
		},
		variants() {
			this.getVariant(true)
		},
		variantId() {
			this.getVariant()
		}
	},
	methods: {
		expandImage(e) {
			clearTimeout(this.imageTO)
			const target = e.currentTarget
			const pos = target.getBoundingClientRect()
			target.style.position = 'fixed'
			target.style.left = `${pos.left}px`
			target.style.top = `${pos.top}px`
			target.style.width = `${pos.width}px`
			target.style.height = `${pos.height}px`
			target.style.zIndex = 10
			this.$nextTick(() => {
				const width = (window.innerHeight * .8) * (pos.width / pos.height)
				target.style.transition = `all .24s ease-out`
				target.style.left = ((window.innerWidth / 2) - (width / 2)) + 'px'
				target.style.top = `10%`
				target.style.width = `${width}px`
				target.style.height = `80%`
			})
		},
		cancelExpand() {
			clearTimeout(this.imageTO)
			const {productImage, imageContainer} = this.$refs
			if(!this.isMobile && productImage && imageContainer) {
				const img = productImage.$el
				if(!img.hasAttribute('style')) return
				const pos = imageContainer.getBoundingClientRect()
				img.style.left = `${pos.left}px`
				img.style.top = `${pos.top}px`
				img.style.width = `${pos.width}px`
				img.style.height = `${pos.height}px`
				img.style.transform = null
				this.imageTO = setTimeout(() => img.removeAttribute('style'), 240)
			}
		},
		selectVariant(color, size) {
			let variant
			if(color && this.selectedSize) {
				variant = this.product.variants.find(v => v.details.color === color && v.details.size === this.selectedSize)
			}
			else if(size && this.selectedColor) {
				variant = this.product.variants.find(v => v.details.size === size && v.details.color === this.selectedColor)
			}
			if(variant) {
				this.selectedColor = variant.details.color || null
				this.selectedSize = variant.details.size || null
			}
			else {
				if(color) {
					variant = this.product.variants.find(v => v.details.color === color)
					this.selectedColor = color
					this.selectedSize = null
				}
				else if(size) {
					variant = this.product.variants.find(v => v.details.size === size)
					this.selectedColor = null
					this.selectedSize = size
				}
			}
			this.$router.replace(`/${this.product.slug}${variant ? `/${variant.id}` : ''}`)
		},
		getVariant(init = false) {
			// this.loadImages()
			this.variant = (this.product && (this.product.variants.find(v => v.id == this.variantId) || (!this.variantId && this.product.variants[this.product.default_variant ? this.product.variants.findIndex(v => v.id == this.product.default_variant) : 0]))) || null
			if(this.variant) {
				document.title = this.variantId ? this.variant.name : this.product.name
				const images = this.product.variants.reduce((list, v) => {
					if((!v.details.color || v.details.color === this.variant.details.color && (!v.details.size || !v.details.size.includes('oz'))) && v.images && v.images.length > list.length) list = v.images
					return list
				}, this.variant.images)
				this.variant.images = images
				this.selectedImage = images[0]
				if(!this.isMobile) {
					Vue.nextTick().then(() => {
						const clipboard = new Clipboard(this.$refs.shareLink)
						clipboard.on('success', () => {
							clearTimeout(this.copiedTO)
							this.copied = true
							this.copiedTO = setTimeout(() => this.copied = false, 2000)
						})
					})
				}
				if(typeof window.ga === 'function') {
					window.ga('ec:addProduct', {
						id: this.product.id,
						name: this.product.name,
						category: this.product.category,
						variant: this.variant.name,
						position: 1
					})
					window.ga('ec:setAction', 'detail')
					window.ga('set', 'page', this.$route.path)
					window.ga('send', 'pageview')
				}
				window.dispatchEvent(new CustomEvent('setLoading', {detail: {value: false}}))
			}
			else if(this.product && this.product.variants.length) return this.$router.push('/404')
			this.hasColors = this.product ? this.product.variants.reduce((colors, v) => {
				if(v.details.color && v.details.color_code && !colors.includes(v.details.color)) colors.push(v.details.color)
				return colors
			}, []).length > 1 : false
			this.hasSizes = this.product ? this.product.variants.filter(v => v.details.size).length > 1 : false
			if(init && this.variant && this.variantId) {
				this.selectedSize = this.variant.details.size || null
				this.selectedColor = this.variant.details.color || null
			}
			this.productSizes = this.product ? this.product.variants.filter(v => !this.variantId || !this.variant || !this.selectedColor || v.details.color === this.selectedColor).reduce((sizes, v) => {
				if(v.details.size && !sizes[v.details.size]) sizes[v.details.size] = v
				return sizes
			}, {}) : null
			this.productColors = this.product ? this.product.variants.filter(v => !this.variantId || !this.variant || !this.selectedSize || v.details.size === this.selectedSize).reduce((colors, v) => {
				if(v.details.color && v.details.color_code && !colors[v.details.color]) colors[v.details.color] = v
				return colors
			}, {}) : null
			this.$nextTick(() => this.positionCircle())
		},
		positionCircle(e) {
			clearTimeout(this.circleTO)
			const c = this.$refs.sizeCircle
			if(c) {
				const size = (e && e.type === 'mouseenter' && e.target) || document.querySelector('button.product-size[selected]') || ((!e || e.type !== 'mouseleave') && document.querySelector('button.product-size'))
				this.circleTO = setTimeout(() => {
					c.style.opacity = (e && e.type === 'mouseenter') || (size && size.hasAttribute('selected')) ? 1 : 0
					if(size) {
						const parentpos = c.parentNode.getBoundingClientRect()
						const cpos = c.getBoundingClientRect()
						const pos = size.getBoundingClientRect()
						c.style.transform = `translate(${(pos.left - parentpos.left) - ((cpos.width - pos.width) / 2)}px, ${(pos.top - parentpos.top) - ((cpos.height - pos.height) / 2)}px)`
					}
				}, 100)
			}
		},
		loadImages() {
			const imgs = this.variants.reduce((images, v) => images.concat(v.images), [])
			const load = new Promise((resolve) => {
				setTimeout(() => resolve(), 1000)
			})
			Promise.all(imgs.map(image => {
				return new Promise((resolve, reject) => {
					const img = new Image()
					img.onload = () => resolve()
					img.onerror = () => reject()
					img.src = image.preview_url
				})
			}).concat(load))
			.then(() => {
				window.dispatchEvent(new CustomEvent('setLoading', {detail: { value: false }}))
			})
			.catch(() => {
				window.dispatchEvent(new CustomEvent('setLoading', {detail: { value: false }}))
			})
		},
		formatPrice(p) {
			if (isNaN(p)) return "0.00"
			return parseFloat(p).toFixed(2)
		},
		addToCart() {
			if(!this.variantId) return
			this.$store.commit('addItemToCart', {
				id: this.variant.id,
				variant_id: this.variant.variant_id,
				quantity: parseInt(this.quantity)
			})
			if(typeof window.ga === 'function') {
				window.ga('ec:addProduct', {
					'id': this.product.id,
					'name': this.product.name,
					'category': this.product.category,
					'variant': this.variant.name,
					'price': this.variant.retail_price,
					'quantity': this.quantity
				})
				window.ga('ec:setAction', 'add')
				window.ga('send', 'event', 'UX', 'click', 'add to cart')
			}
			this.quantity = 1
			if(this.isMobile) window.dispatchEvent(new CustomEvent('openCart'))
			else this.$router.push('/cart')
		},
	},
	mounted: function() {
		this.getVariant(true)
		window.addEventListener('resize', this.positionCircle)
	},
	beforeDestroy: function() {
		window.removeEventListener('resize', this.positionCircle)
	}
}
</script>

<style scoped>
.container {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	padding: 0 6.04vw;
	z-index: 3;
	margin-top: 5vh;
}

.share {
	position: fixed;
	top: 0;
	right: 13.96vw;
	transition: transform .16s ease-in;
	z-index: 3;
}

#app[newsletter] .share {
	transform: translateY(6.44vh);
}

.share > p {
	text-align: center;
	text-transform: uppercase;
	font-size: 1.38vh;
	margin-top: .48vh;
}

.share .share-links {
	display: flex;
}

.share .share-link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5vh;
	height: 5vh;
	background-size: auto 38.88%;
}

.share .share-link .ic {
	width: 1.85vh;
	height: 1.85vh;
	transition: transform .16s ease-out;
}

.share .share-link:hover .ic {
	transform: translateY(2px);
}

.share .share-link.ic-link {
	background-color: #208E7F;
}

.share .share-link.ic-facebook {
	background-color: #3B5998;
}

.share .share-link.ic-twitter {
	background-color: #4099FF;
}

.share .share-link.ic-pinterest {
	background-color: #BD081C;
}

.share .copied {
	position: absolute;
	top: .875vh;
	right: 100%;
	margin-right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 9vh;
	height: 3.5vh;
	background: #208E7F;
	border-radius: 0;
	pointer-events: none;
	opacity: 0;
	transition: opacity .16s;
}

.share .copied[active] {
	opacity: 1;
}

.share .copied:after {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: .875vh 0 .875vh .875vh;
	border-color: transparent #208E7F;
	display: block;
	width: 0;
	z-index: 1;
	right: -.875vh;
	top: .875vh;
}

.product-images {
	position: absolute;
	right: 100%;
	top: 0;
	margin-right: .93vh;
}

.product-images a {
	display: block;
	width: 7.77vh;
	height: 7.77vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-bottom: .93vh;
}

.product-image {
	position: relative;
	width: 23.7vw;
	height: 59.9vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin-left: 7.4vw;
	margin-right: 3.125vw;
	flex-shrink: 0;
}

.product[limited] .product-image:after {
	content: '';
	position: absolute;
	top: -5px;
	left: -5px;
	height: calc(100% + 10px);
	width: calc(100% + 10px);
	background: linear-gradient(60deg, #EDBA52, #EDBA52, #EDBA52, #fff, #EDBA52, #EDBA52, #EDBA52);
	z-index: -1;
	animation: animatedgradient 4s ease alternate infinite;
	background-size: 1000% 1000%;
}

.product[limited] .product-image {
	border: 5px solid transparent;
}

.product[limited] .product-images {
	top: -5px;
	margin-right: 12px;
}

.product-image .image-container {
	cursor: pointer;
}

.product-image .limited-tag {
	position: absolute;
	top: -5vh;
	right: -2.7vw;
	width: 5.1vw;
	height: 9.07vh;
	background: url(../assets/images/bps_i_limited.svg) no-repeat center/contain;
}

.product-name {
	font-size: 2.4vh;
	font-weight: 600;
	margin-bottom: .74vh;
}

.product-price {
	font-size: 2.59vh;
	font-weight: 900;
	margin-bottom: .37vh;
}

.product-limited {
	color: #EDBA52;
	font-size: 1.48vh;
	font-family: 'Inter';
	font-weight: bold;
}

.product-brand {
	font-size: 1.38vh;
	font-family: 'Inter';
}

.product-variants {
	margin: 2.22vh 0;
}

.product-variants label {
	display: block;
	color: #208E7F;
	margin-bottom: 4px;
	font-family: 'Inter';
	font-weight: 500;
	text-transform: uppercase;
}

.product-variants .product-color {
	position: relative;
	display: inline-flex;
	border: 1px solid #FAFAFA;
	width: 2.22vh;
	padding: 0;
	margin-right: 4px;
	border-radius: 50%;
	overflow: hidden;
}

.product-variants .product-color .color {
	flex-grow: 1;
	width: 100%;
	height: 2vh;
}

.product-variants .product-color:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 87.5%;
	height: 87.5%;
	margin: auto;
	background: url(../assets/images/bps_i_check.svg) no-repeat center/45% 45%;
	opacity: 0;
}

.product-variants .product-color[selected]:after {
	opacity: 1;
}

.product-variants .product-sizes {
	position: relative;
	margin: 0 -6px;
}

.product-variants .product-sizes .size-circle {
	position: absolute;
	top: 0;
	left: 0;
	width: 5.2vh;
	height: 5.2vh;
	border: 1px solid;
	border-radius: 50%;
	transition: transform .16s ease-out, opacity .16s ease-out;
}

.product-variants .product-size {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 4.44vh;
	height: 4.44vh;
	border-radius: 50%;
	border: none;
	padding: 0;
	background: none;
	text-transform: uppercase;
	font-family: 'Inter';
	font-weight: 500;
	font-size: 1.11vh;
	margin: 0 6px 12px;
}

.product-variants .product-size span {
	position: relative;
	z-index: 1;
}

.product-variants .product-size[selected] {
	border-color: transparent;
	/* background: #fff; */
	color: #221f1f;
}

.product-variants .product-size:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	/* transform: scale(-4px); */
	border: 1px solid #fff;
	opacity: .23;
}

.product-variants .product-size:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	/* border: 1px solid #fff; */
	background: #fff;
	transform: scale(0);
	transition: transform .16s ease-in;
}

.product-variants .product-size[selected]:before,
.product-variants .product-size[selected]:after {
	opacity: 1;
	transform: scale(1);
}

.product-description,
.product-artist,
.product-note {
	font-family: 'Inter';
}

.product-description h5 {
	font-size: 1.85vh;
	font-weight: bold;
	text-transform: uppercase;
	/* margin-bottom: 4px; */
}

.product-description .product-description-text >>> p {
	margin: .74vh 0;
}

.product-artist,
.product-note {
	font-style: italic;
}

.product-artist {
	margin-top: 1.48vh;
}

.product-unavailable {
	background: #c9433c;
	padding: 1vh 2vh;
	margin: 2vh 0;
	font-size: 1.48vh;
	font-weight: bold;
}

.product-quantity {
	margin-top: 4.4vh;
}

.product-quantity input {
	width: 5.74vh;
	height: 5.74vh;
	text-align: center;
	margin: 0 .625vw 0 0;
	font-size: 1.85vh;
}

.product-quantity button {
	width: 17.7vw;
	height: 5.74vh;
	border: 5px solid #BA9241;
	background: #EDBA52;
	color: #fff;
	font-size: 1.85vh;
	font-weight: 900;
	text-transform: uppercase;
}

.product-quantity button[disabled] {
	opacity: .5;
	pointer-events: none;
}

.box {
	box-sizing: border-box;
	position: relative;
	border-radius: 8px;
}

.box.left {
	width: 336px;
	margin-right: 48px;
}

.box.left a {
	color: #a1af3e;
	text-decoration: underline;
}

.box.right {
	padding: 24px 32px;
	width: 400px;
	height: 425px;
	background: #fff;
	color: #221f1f;
}

.box.right:after {
	content: "";
	position: absolute;
	left: 100%;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 250px;
	height: 360px;
	background: url(/images/bps_bg_payment_2x.png) no-repeat center;
	background-size: cover;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: inset 19px 0 26px rgba(34, 31, 31, 0.1);
}

.box.left,
.box.right {
	display: inline-block;
	vertical-align: middle;
}

.box.paid {
	display: none;
	text-align: center;
}

.invoice-date {
	margin-bottom: 16px;
	color: rgba(250, 250, 250, 0.77);
}

.invoice-info b {
	font-size: 12px;
	color: rgba(250, 250, 250, 1);
}

.invoice-info p {
	margin: 0;
	font-size: 12px;
	line-height: 20px;
}

.invoice-ref {
	margin: 16px 0 12px;
	color: rgba(250, 250, 250, 0.77);
	font-size: 12px;
}

[loading] .box {
	display: none;
}

#card,
input {
	border: none;
	border-bottom: 1px solid rgba(34, 31, 31, 0.12);
	padding: 8px 0;
	background: #fff;
	color:#221f1f;
}

input {
	padding: 0;
	height: 28px;
	box-sizing: border-box;
	/* display: block;
	width: 100%; */
	margin-bottom: 8px;
	font: inherit;
	font-size: 14px;
	color: #221f1f;
	outline: none;
}

#cardErrors {
	margin-top: 12px;
	color: #ea2c3c;
	font-weight: bold;
	font-size: 12px;
}

[paid] #paymentForm {
	display: none;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #221f1f;
	opacity: 0.77;
	text-transform: uppercase;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: #221f1f;
	opacity: 0.77;
	text-transform: uppercase;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: #221f1f;
	opacity: 0.77;
	text-transform: uppercase;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: #221f1f;
	opacity: 0.77;
	text-transform: uppercase;
}

img.logo {
	width: 100%;
	display: block;
	margin-bottom: 16px;
}

.page-title {
	font-size: 18px;
	position: relative;
	color: rgba(250, 250, 250, 0.77);
}

.page-title:after {
	content: "";
	position: absolute;
	top: 14px;
	bottom: 0;
	right: 0;
	left: 175px;
	height: 2px;
	background: #fafafa;
}

.breakdown .price {
	float: right;
	margin-left: 24px;
	padding-left: 8px;
}

.iframe-container {
	min-height: 150px;
}

.breakdown {
	font-weight: 300;
	margin-top: 8px;
}

.breakdown label {
	padding-right: 8px;
}

.breakdown label,
.breakdown .price {
	background: #fff;
	position: relative;
	z-index: 1;
}

.breakdown .amount {
	position: relative;
	line-height: 1;
	margin-bottom: 12px;
}

.breakdown .amount:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1px;
	height: 1px;
	background: #221f1f;
	opacity: 0.12;
}

.breakdown .total {
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	margin-top: 16px;
}

.breakdown .total:after {
	display: none;
}

.breakdown .total label {
	font-size: 10px;
	display: block;
	padding: 0;
	letter-spacing: 1.8px;
}

.breakdown .total .price {
	font-size: 20px;
	float: none;
	padding: 0;
	margin: 6px 0 0;
	display: block;
	letter-spacing: 3.2px;
}

.iframe-container .success {
	display: none;
}

[paid] .iframe-container .success {
	display: block;
	padding: 8px 32px 0;
	text-align: center;
	font-size: 16px;
}

[paid] .iframe-container .success h2 {
	text-transform: uppercase;
	margin: 0;
	font-size: 28px;
	color: #a1af3e;
}

.iframe-container .success .paid-btn {
	position: absolute;
	background: #a1af3e;
	color: #fff;
	text-align: center;
	line-height: 60px;
	bottom: 0;
	left: 0;
	right: 0;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 18px;
}

.iframe-container .processing {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(255, 255, 255, 0.72);
	display: none;
	z-index: 11;
}

.iframe-container[processing] {
	z-index: 10;
}

.iframe-container[processing] .processing {
	display: block;
}

.iframe-container .processing span {
	height: 90px;
	line-height: 24px;
	font-size: 24px;
	text-transform: uppercase;
	letter-spacing: 1.8px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: center;
}

@media screen and (max-width: 1023px) {
	.container {
		display: block;
		padding: 0;
		margin: 0 0 70px;
	}
	.product-image {
		width: auto;
		height: calc(100vh - 242px);
		margin: 0;
	}
	.product-image .product-image-slider {
		height: 100%;
	}
	.product-image .product-image-slider .image-slide {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	.product-image .bullets {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		margin-top: 6px;
	}
	.product[limited] .product-image .bullets {
		margin-top: 13px;
	}
	.product-image .bullets .bullet {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 14px;
		height: 14px;
		background: none;
		border: none;
		margin: 0;
		opacity: .23;
		padding: 0;
	}
	.product-image .bullets .bullet:after {
		content: '';
		display: inline-block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #fff;
	}
	.product-image .bullets .bullet[selected] {
		opacity: 1;
	}
	.product-details {
		padding: 0 32px;
		margin-top: 28px;
	}
	.product[limited] .product-details {
		margin-top: 33px;
	}
	.product-name {
		font-size: 26px;
		line-height: 24px;
		margin-bottom: 24px;
	}
	.product-price {
		position: absolute;
		right: 0;
		border: 5px solid #155C52;
		border-right: none;
		background:#208E7F;
		line-height: 47px;
		padding: 0 26px;
		font-size: 28px;
		margin-top: -10px;
	}
	.product-limited {
		font-size: 16px;
	}
	.product-unavailable {
		font-size: 16px;
		margin: 16px 0;
	}
	.product-brand {
		font-size: 14px;
	}
	.product-variants {
		margin: 30px 0;
	}
	.product-variants label {
		margin-bottom: 12px;
	}
	.product-variants .product-colors {
		margin: 0 -6px;
	}
	.product-variants .product-color {
		width: 32px;
		height: 32px;
		margin: 0 6px 12px;
	}
	.product-variants .product-color .color {
		height: 32px;
	}
	.product-quantity {
		margin: 0 0 30px;
	}
	.product-quantity button {
		width: 100%;
		height: 62px;
		font-size: 20px;
	}
	.product-description h5 {
		font-size: 20px;
	}
	.product-variants .product-size {
		width: 48px;
		height: 48px;
		font-size: 12px;
	}
	.product-variants .product-sizes .size-circle {
		width: 54px;
		height: 54px;
	}
	.product-details > a.btn {
		display: inline-flex;
		align-items: center;
		font: bold 16px/1 'Inter';
		margin-top: 30px;
	}
	.product-details > a.btn .ic {
		width: 14px;
		height: 14px;
		margin-right: 8px;
	}
}
</style>

